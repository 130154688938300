import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import LegalDoc from '../../modules/legal/components/legal-doc';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = function MerchantTerms({
  children
}) {
  return <LegalDoc title="Merchant Terms of Use" updated="19 Feb 2020">{children}</LegalDoc>;
};

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`These Terms of Use set out the terms and conditions on which you may use the Merchant Dashboard (accessible at `}<a parentName="p" {...{
        "href": "https://clo.fidel.uk"
      }}>{`https://clo.fidel.uk`}</a>{`).`}</p>
    <p>{`Please read these Terms of Use carefully. By using the Merchant Dashboard, you agree to be bound by these Terms of Use. If you do not agree, you must refrain from using the Merchant Dashboard.`}</p>
    <ol>
      <li parentName="ol">{`Information about Fidel`}
        <ol parentName="li">
          <li parentName="ol">{`The Merchant Dashboard is provided by Enigmatic Smile (“Fidel”, or “us”, “we”, “our”), a company incorporated and registered in England and Wales under company number 08869163 whose registered office is at Swift House Ground Floor, 18 Hoffmanns Way, Chelmsford, Essex, England, CM1 1GU. Fidel’s VAT number is 247613206.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Interpretation`}
        <ol parentName="li">
          <li parentName="ol">{`In these Terms of Use, unless the context requires otherwise, the following words and expressions have the following meaning:`}
            <ul parentName="li">
              <li parentName="ul"><strong parentName="li">{`“Account”`}</strong>{` means an account with Fidel to access and use the Merchant Dashboard;`}</li>
              <li parentName="ul"><strong parentName="li">{`“American Express”`}</strong>{` means American Express Payment Services Limited and its affiliated companies;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Confidential Information”`}</strong>{` means information which is identified as confidential or proprietary by either party or by the nature of which is clearly confidential or proprietary. The Confidential Information of Fidel includes the Fidel Data and any other information made available to the Merchant through the Merchant Dashboard;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Customer”`}</strong>{` means an operator of a Loyalty Programme, which has entered into an agreement with Fidel to access and use the Fidel Service;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Fidel Data”`}</strong>{` means the aggregated and anonymised information and statistics about Transactions that Fidel makes available to the Merchant through the Merchant Dashboard;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Fidel Service”`}</strong>{` means the source code, the application programming interface, and any related software Fidel makes available as a service to Customers for the purpose of allowing the Customers to connect Users’ payment cards with Loyalty Programmes in connection with Transactions;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Loyalty Programme”`}</strong>{` means a rewards service or a card-linked offers programme;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Mastercard”`}</strong>{` means Mastercard Europe SA and its affiliated companies;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Merchant”`}</strong>{` or “you”, “your” means the merchant accessing the Merchant Dashboard, and which has enrolled in a Loyalty Programme;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Merchant Data”`}</strong>{` means Merchant’s MIDs, the Merchant’s name, the Merchant’s outlet addresses, and any other information required to help identify the Merchant;`}</li>
              <li parentName="ul"><strong parentName="li">{`“MID”`}</strong>{` means the Merchant ID;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Payment Card”`}</strong>{` means any payment card, or other payment device or service, issued by a Payment Provider;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Payment Provider”`}</strong>{` means a payment provider that provides Transaction Data to Fidel to enable Fidel to provide the Fidel Service (including Visa, Mastercard, and American Express);`}</li>
              <li parentName="ul"><strong parentName="li">{`“Terms of Use”`}</strong>{` means these terms of use;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Third Party Sites”`}</strong>{` has the meaning given in clause 6;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Transaction”`}</strong>{` means a point of sale purchase made by a User with an enrolled payment card at a Merchant location;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Transaction Data”`}</strong>{` means data supplied via a Payment Provider relating to a Transaction, including MID, card token, date, time, and amount;`}</li>
              <li parentName="ul"><strong parentName="li">{`“User”`}</strong>{` means any person who has registered their Payment Card with a Customer to receive rewards under a Loyalty Programme;`}</li>
              <li parentName="ul"><strong parentName="li">{`“Virus”`}</strong>{` means any thing or device (including any software, code, file or program) which may: prevent, impair, or otherwise adversely affect the operation of any computer software, hardware, or network, any telecommunications service, equipment, or network, or any other service or device, or prevent, impair, or otherwise adversely affect access to, or the operation of, any program or data, including the reliability of any program or data (whether by re-arranging, altering, or erasing the program or data in whole or in part, or otherwise), or adversely affect the user experience, including worms, Trojan horses, viruses, and other similar things or devices; and`}</li>
              <li parentName="ul"><strong parentName="li">{`“Visa”`}</strong>{` means Visa Europe Services Inc. and its affiliated companies.`}</li>
            </ul>
          </li>
        </ol>
      </li>
      <li parentName="ol">{`Access to the Merchant Dashboard`}
        <ol parentName="li">
          <li parentName="ol">{`Fidel grants to the Merchant a non-exclusive, non-transferable, personal right to access and use the Merchant Dashboard solely in connection with the Merchant’s participation in any Loyalty Programmes, as permitted by the functionality of the Merchant Dashboard (including to view the Fidel Data and any other information made available through the Merchant Dashboard]), in accordance with these Terms of Use.`}</li>
          <li parentName="ol">{`To access the Merchant Dashboard, the Merchant will first need to set up an Account. Fidel encourages the Merchant to use strong passwords (passwords that use a combination of upper and lower case letters, numbers, and symbols) with its Account.`}</li>
          <li parentName="ol">{`The Merchant is responsible for maintaining the confidentiality of its login details and any activities that occur under its Account. The Merchant is also responsible for ensuring that all persons who it authorises to access the Merchant Dashboard are aware of these Terms of Use and that they comply with them.`}</li>
          <li parentName="ol">{`The Merchant must use all reasonable endeavours to prevent any unauthorised access to, or use of, the Merchant Dashboard, and in the event of any such unauthorised access or use, as soon as reasonably practicable notify Fidel at `}<a parentName="li" {...{
              "href": "mailto:support@fidelapi.com"
            }}>{`support@fidelapi.com`}</a>{`.`}</li>
          <li parentName="ol">{`If the Merchant has any concerns about the login details for its Account or thinks that such details have been misused, the Merchant should promptly notify Fidel at `}<a parentName="li" {...{
              "href": "mailto:support@fidelapi.com"
            }}>{`support@fidelapi.com`}</a>{` and take such other steps as may reasonably be required by Fidel.`}</li>
          <li parentName="ol">{`Fidel may disable any Account at any time and at Fidel’s sole discretion, if the Merchant fails, in any material respect, to comply with any of the provisions of this Agreement.`}</li>
          <li parentName="ol">{`The Merchant recognises that Fidel is always innovating and finding ways to improve the Merchant Dashboard with new features and services. Therefore, the Merchant agrees that the Merchant Dashboard may change from time to time, and no warranty, representation, or other commitment is given in relation to the continuity of any functionality of the Merchant Dashboard.`}</li>
          <li parentName="ol">{`The Merchant shall indemnify and defend Fidel, its agents and contractors, from and against any and all losses, damages, claims, liabilities, and expenses (including reasonable lawyers’ fees) arising out of any claim brought by a third party relating to the Merchant’s use of the Merchant Dashboard (except to the extent caused by Fidel’s negligence).`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Merchant’s Consent to Monitor Payment Activity`}
        <ol parentName="li">
          <li parentName="ol">{`The Merchant grants to Fidel a licence to access the Merchant Data and to transfer the Merchant Data to the Payment Providers, and the Merchant consents to the Payment Providers accessing and using the Merchant Data to monitor payments made via the Merchant’s Payment Card terminals, and to access the corresponding payment data, as required by the Fidel Service, for the sole purpose of enabling the Payment Providers to:`}
            <ol type="a">
  <li>identify, track and record Transactions;</li>
  <li>provide the Transaction Data to Fidel; and</li>
  <li>validate and where necessary update the accuracy of their records.</li>
            </ol>
          </li>
          <li parentName="ol">{`The Merchant will update any Merchant Data provided to Fidel promptly after any change to the relevant Merchant Data.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Restrictions on use of the Merchant Dashboard`}
        <ol parentName="li">
          <li parentName="ol">{`The Merchant:`}
            <ol type="a">
  <li>must comply with all applicable laws and regulations, and these Terms of Use, with respect to its use of the Merchant Dashboard and its activities under these Terms of Use;</li>
  <li>must obtain and shall maintain all necessary licences, consents, permits, and permissions necessary for Fidel to perform its obligations to the Merchant, and for the Merchant to use the Merchant Dashboard, under these Terms of Use;</li>
  <li>must ensure that its network and systems, including its internet browser, complies with the relevant specifications provided by Fidel in writing from time to time; and</li>
  <li>is solely responsible for procuring and maintaining its network connections and telecommunications links from its systems in order to access and use the Merchant Dashboard.</li>
            </ol>
          </li>
          <li parentName="ol">{`The Merchant shall not:`}
            <ol type="a">
  <li>access, store, distribute, or transmit any Virus through the Merchant Dashboard;</li>
  <li>use the Merchant Dashboard to access, store, distribute, or transmit any material that is unlawful, illegal, harmful, threatening, defamatory, obscene, abusive, or infringing of any rights;</li>
  <li>use the Merchant Dashboard in a manner that is illegal or causes damage or injury to any person or property;</li>
  <li>use any automated system, including without limitation “robots”, “spiders”, “offline readers”, to access the Merchant Dashboard in a manner that sends more request messages to the Merchant Dashboard than a human can reasonably produce in the same period of time by using a conventional online web browser;</li>
  <li>attempt to interfere with or compromise the integrity or security of the Merchant Dashboard. Fidel reserves the right, without liability or prejudice to its other rights under these Terms of Use, to disable the Merchant’s Account for any material or persistent breaches of this clause 5.2.</li>
 </ol>
          </li>
          <li parentName="ol">{`The Merchant shall not access without authority, interfere with, damage, or disrupt:`}
            <ol type="a">
  <li>any part of the Merchant Dashboard;</li>
  <li>any equipment or network on which the Merchant Dashboard is stored;</li>
  <li>any software used in the provision of the Merchant Dashboard; or</li>
  <li>any equipment or network or software owned or used by any third party.</li>
            </ol>
          </li>
          <li parentName="ol">{`Fidel may monitor the Merchant’s use of the Merchant Dashboard to ensure quality, improve the Merchant Dashboard, and verify the Merchant’s compliance with these Terms of Use.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Third Party Sites`}
        <p>The Merchant Dashboard may contain links to, or call the servers of, third party websites or services that are not under the control of Fidel, solely at the direction of and/or convenience to the Merchant (“Third Party Sites”). As such, Fidel is not responsible for, and makes no express or implied warranties with regards to, the information, content or other material, products, or services that are contained on or are accessible through, or the policies regarding the use and privacy of,
Third Party Sites. Access to and use of Third Party Sites, including the information, content, material, products, and services on such websites or available through such websites, is solely at the Merchant’s risk.</p>
      </li>
      <li parentName="ol">{`Important Note on Intellectual Property Rights`}
        <ol parentName="li">
          <li parentName="ol">{`Fidel is the owner or licensee of all intellectual property rights in the Merchant Dashboard and the Fidel Data. These works are protected by copyright and other laws around the world. All such rights are reserved. Except as expressly stated herein, the Merchant is not granted any intellectual property rights or any other rights or licenses in respect of the Merchant Dashboard or the Fidel Data.`}</li>
          <li parentName="ol">{`All Fidel Data shall at all times be and remain the sole and exclusive property of Fidel or its licensors.`}</li>
          <li parentName="ol">{`The Merchant may access and display the Fidel Data and other information made available to the Merchant through the Merchant Dashboard on a computer screen, print individual pages, or save such pages in electronic form on a disk (but not on a server or on a storage peripheral connected to a network). Information made available on the Merchant Dashboard may not be duplicated, copied, or sold, resold, broadcasted, disseminated, re-transmitted, or re-distributed, in whole or in part, in any format or to any third party (including any person within the Merchant’s organisation, company or business) without the express written consent of Fidel. Dissemination and re-distribution includes making any such information available via the Internet, any automated email forwarding service, or via any other electronic means of access.`}</li>
          <li parentName="ol">{`The Merchant shall not use any Fidel Data or any other information made available to the Merchant through the Merchant Dashboard for any marketing, internal modelling, or data analytics purposes, including behavioural advertising (such as identifying segments of a database of Users that exhibit certain shopping behaviour at certain retailers, or certain types of retailer, and provide a segment with an offer or other relevant message).`}</li>
          <li parentName="ol">{`The Merchant shall not, and shall not permit any person to, except as may be allowed by any applicable law which is incapable of exclusion by agreement between the parties:`}
            <ol type="a">
  <li>except to the extent expressly permitted under these Terms of Use, attempt to copy, modify, duplicate, create derivative works from, frame, mirror, republish, download, display, transmit, or distribute all or any portion of the Merchant Dashboard in any form or media or by any means; or</li>
  <li>attempt to reverse compile, disassemble, reverse engineer, or otherwise reduce to human-perceivable form all or any part of the Merchant Dashboard; or</li>
  <li>access all or any part of the Merchant Dashboard in order to build a product or service which competes with the Merchant Dashboard or the Fidel Service;</li>
  <li>use the Merchant Dashboard to provide services similar to the Merchant Dashboard or the Fidel Service to third parties; or</li>
  <li>license, sell, rent, lease, transfer, assign, distribute, display, disclose, or otherwise commercially exploit, or otherwise make the Merchant Dashboard available, to any third party except as permitted by these Terms of Use.</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol">{`Publicity, Case Studies, and Feedback`}
        <p>Subject to the Merchant giving its prior written approval, Fidel may use the Merchant’s name, logo and related trade marks in any of Fidel’s publicity or marketing materials (whether in printed or electronic form for the purpose of highlighting that the Merchant participates in Loyalty Programmes which use the Fidel Service, and alongside any testimonials that the Merchant has agreed to give. The giving of any such approval by the Merchant shall be deemed to include the grant to Fidel of
such rights as are necessary to use its name, logo, related trade marks, and testimonials for the purposes of this clause 8.</p>
      </li>
      <li parentName="ol">{`Data Protection`}
        <p>The Merchant’s use of the Merchant Dashboard may involve the transmission to Fidel of certain information about the Merchant. The Merchant’s privacy is important to Fidel. In order to understand how Fidel collects, uses, transfers and shares information about the Merchant, the Merchant should read Fidel’s <a href="/legal/merchant-privacy">Privacy Policy</a>.</p>
      </li>
      <li parentName="ol">{`Confidential Information`}
        <ol parentName="li">
          <li parentName="ol">{`Each party may be given access to Confidential Information from the other party in order to perform its obligations under these Terms of Use.`}</li>
          <li parentName="ol">{`Each party shall hold the other party’s Confidential Information in confidence and, unless required by law, shall not use or make the other party’s Confidential Information available for use for any purpose other than as required to perform these Terms of Use.`}</li>
          <li parentName="ol">{`Each party shall take all reasonable steps to ensure that the other party’s Confidential Information to which it has access is not disclosed or distributed by its employees or agents in violation of these Terms of Use.`}</li>
          <li parentName="ol">{`The confidentiality obligations in this clause shall not apply to any information which:`}
            <ol type="a">
  <li>was demonstrably in the receiving party’s possession before disclosure by the disclosing party;</li>
  <li>is developed through the independent efforts of the receiving party;</li>
  <li>is lawfully disclosed to the receiving party from a third party without restriction on disclosure; or</li>
  <li>is disclosed with the express prior written consent of the disclosing party.</li>
            </ol>
          </li>
          <li parentName="ol">{`Either party may disclose Confidential Information of the other party to the extent that it is required to be disclosed by law, or by any court of competent jurisdiction or regulatory or administrative body, provided that, to the extent it is legally permitted to do so, it gives the other party as much prior notice of the disclosure as possible.`}</li>
          <li parentName="ol">{`On termination of the Merchant’s Account for any reason, or earlier on request, each party shall promptly:`}
            <ol parentName="li">
              <li parentName="ol">{`return to the disclosing party (or, at the disclosing party’s request, destroy) all documents and materials (and any copies) containing the disclosing party’s Confidential Information (provided that the receiving party may retain documents and materials containing Confidential Information to the extent required by law or any applicable governmental regulatory authority); and`}</li>
              <li parentName="ol">{`on request, certify in writing to the disclosing party that it has complied with the requirements of this clause.`}</li>
            </ol>
          </li>
        </ol>
      </li>
      <li parentName="ol">{`Exclusion of Warranties`}
        <ol parentName="li">
          <li parentName="ol">{`The Merchant Dashboard is provided to the Merchant on an “AS IS” basis and Fidel will not be responsible for any interruptions, delays, failures, or non-availability affecting the Merchant Dashboard caused by third party services (including Third Party Sites), errors or bugs in third party software, hardware, or the Internet on which Fidel relies to provide Merchant Dashboard, or any changes to the Merchant Dashboard made by or on behalf of the Merchant, and the Merchant acknowledges that Fidel does not control such third party services and that such errors and bugs are inherent in the use of such software, hardware, and the Internet. Fidel gives no representations, warranties, conditions or other terms of any kind in respect of the Merchant Dashboard, whether express or implied, including, but not limited to, warranties of satisfactory quality, merchantability, fitness for a particular purpose, or non-infringement, which are hereby excluded to the full extent permitted by law.`}</li>
          <li parentName="ol">{`The information contained on the Merchant Dashboard (including the Fidel Data) is provided by Fidel in good faith on an “AS IS” basis, and the Merchant must verify the information before using it. Fidel gives no representations or warranties that the information contained on the Merchant Dashboard is reliable, accurate, or complete, or that the Merchant’s access to the information will be uninterrupted, timely or secure. Fidel does not accept responsibility for loss suffered as a result of reliance on the accuracy or currency of information contained on the Merchant Dashboard.`}</li>
          <li parentName="ol">{`Without limiting the effect of clause 11.2, Fidel does not warrant, and neither Fidel nor any Payment Provider shall have no liability in respect of, the following:`}
            <ol type="a">
  <li>access to information made available through the Merchant Dashboard (including the Fidel Data) will be error-free or free from interruption; or</li>
  <li>the information made available through the Merchant Dashboard (including the Fidel Data) is accurate, complete, reliable, secure, useful, fit for purpose, or timely.</li>
            </ol>
          </li>
          <li parentName="ol">{`The Merchant acknowledges that Fidel requires access to the Merchant Data and other third party data sources, whether controlled by the Merchant or a third party, that the Merchant may elect to use with the Merchant Dashboard. The Merchant agrees that Fidel is not responsible for the non-availability of any such data source.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Fidel’s Liability`}
        <ol parentName="li">
          <li parentName="ol">{`Subject to clause 12.2, Fidel will not be liable to the Merchant for any loss or damage, whether in contract, tort (including negligence) or restitution, or for breach of statutory duty or misrepresentation, or otherwise, arising under or in connection with the Merchant’s use of, or inability to use, the Merchant Dashboard, or the Merchant’s use or reliance on any Fidel Data or any other content or information displayed on the Merchant Dashboard. In particular, Fidel will not be liable for:`}
            <ol type="a">
  <li>loss (whether direct or indirect) of profit, goodwill, business, business opportunity, revenue, turnover, or reputation;</li>
  <li>loss (whether direct or indirect) of anticipated saving or wasted expenditure; loss of or damage to data; or any special, indirect, or consequential damage or loss, arising under or in connection with these Terms of Use.</li>
            </ol>
          </li>
          <li parentName="ol">{`Nothing in these Terms of Use excludes either party’s liability for:`}
            <ol type="a">
  <li>death or personal injury caused by that party’s negligence;</li>
  <li>fraud or fraudulent misrepresentation; or</li>
  <li>any other liability which cannot be limited or excluded by law.</li>
            </ol>
          </li>
          <li parentName="ol">{`Neither Fidel nor any Payment Provider shall have any liability to the Merchant in respect of any Loyalty Programme.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`Suspension and Termination`}
        <ol parentName="li">
          <li parentName="ol">{`The Merchant’s access to the Merchant Dashboard will automatically end if the Merchant ceases to participate in any Loyalty Programmes.`}</li>
          <li parentName="ol">{`Fidel may terminate the Merchant’s access to the Merchant Dashboard with immediate effect if the Merchant:`}
            <ol type="a">
  <li>infringes Fidel’s intellectual property rights in the Merchant Dashboard;</li>
  <li>is in breach of clause 5 or clause 7.5 of these Terms of Use;</li>
  <li>is in breach of any applicable law;</li>
  <li>is in material breach of any of the terms of these Terms of Use and either that breach is incapable of remedy or, if remediable, the Merchant fails to remedy the breach within thirty (30) days after receiving written notice requiring it to remedy the breach; or</li>
  <li>is unable to pay its debts (within the meaning of section 123 of the Insolvency Act 1986) or becomes insolvent, or is subject to an order or a resolution for its liquidation, administration, winding-up or dissolution (other than for the purposes of a solvent amalgamation or reconstruction), or has an administrative or other receiver, manager, trustee, liquidator, administrator, or similar officer appointed over all or any substantial part of its assets, or enters into or proposes any composition or arrangement with its creditors generally, or is subject to any analogous event or proceeding in any applicable jurisdiction.</li>
            </ol>
          </li>
          <li parentName="ol">{`Without prejudice to any other rights or remedies available to Fidel, Fidel may immediately suspend the Merchant’s Account in whole or in part, without prior notice, if the Merchant is in material breach of any terms of these Terms of Use.`}</li>
          <li parentName="ol">{`Subject to clause 13.5, on termination of the Merchant’s Account for any reason all rights and licences granted under the Terms of Use shall immediately terminate and the Merchant’s right to access and use the Merchant Dashboard will end.`}</li>
          <li parentName="ol">{`Notwithstanding any termination or suspension of the Merchant’s Account, the provisions of clause 4 shall survive and continue to apply until such time as the Merchant ceases all participation in any Loyalty Programmes.`}</li>
        </ol>
      </li>
      <li parentName="ol">{`General`}
        <ol parentName="li">
          <li parentName="ol">{`Written communications`}
            <p>Applicable laws may require that some of the information or communications that Fidel sends to the Merchant should be in writing. When using the Merchant Dashboard, the Merchant accepts that communication with Fidel will mainly be electronic. Fidel will contact the Merchant by email or provide the Merchant with information through the Merchant Dashboard. For contractual purposes, the Merchant agrees to this electronic means of communication and the Merchant acknowledges that all contracts, notices, information, and other communications that Fidel provides to the Merchant electronically comply with any legal requirement that such communications be in writing.</p>
          </li>
          <li parentName="ol">{`Notices`}
            <p>All notices given by the Merchant to Fidel must be given to <a href="mailto:consent@fidelapi.com">consent@fidelapi.com.</a> Fidel may give notice to the Merchant at either the e-mail or postal address the Merchant provides to Fidel, or any other way Fidel deems appropriate. Notice will be deemed received and properly served immediately when posted on the Fidel Service or 24 hours after an e-mail is sent or three days after the date of posting of any letter. In proving the service of any notice, it will be sufficient to prove,
in the case of a letter, that such letter was properly addressed, stamped and placed in the post and, in the case of an e-mail that such e-mail was sent to the specified e-mail address of the addressee. Neither party may use email or other electronic communications as a valid means of serving on the other party any legal and/or court document, or any other document relating to or in connection with any proceedings or claims arising out of or in connection with these Terms of
Use.</p>
          </li>
          <li parentName="ol">{`Waiver`}
            <p>No forbearance or delay by either party in enforcing its rights shall prejudice or restrict the rights of that party, and no waiver of any such rights or of any breach of any contractual terms shall be deemed to be a waiver of any other right or of any other breach.</p>
          </li>
          <li parentName="ol">{`Severability`}
            <p>If any provision of these Terms of Use is judged to be illegal or unenforceable, the continuation in full force and effect of the remainder of the provisions shall not be prejudiced.</p>
          </li>
          <li parentName="ol">{`Amendments`}
            <p>Fidel may amend these Terms of Use at any time. Fidel will post a copy of the amended Terms of Use on the Merchant Dashboard. The Merchant’s continued use of the Merchant Dashboard after these Terms of Use have been amended will be considered as the Merchant’s acceptance of the amended Terms of Use.</p>
          </li>
          <li parentName="ol">{`Assignment`}
            <p>The Merchant may not assign or purport to assign these Terms of Use or any of its obligations without Fidel’s prior written consent.</p>
          </li>
          <li parentName="ol">{`Third Party Rights`}
            <p>A person that is not a party to these Terms of Use may not enforce any of its terms under the Contracts (Rights of Third Parties) Act 1999.</p>
          </li>
          <li parentName="ol">{`Entire Agreement`}
            <p>These Terms of Use contain the whole agreement between the parties relating to the subject matter hereof and supersedes all prior agreements, arrangements, and understandings between the parties relating to that subject matter.</p>
          </li>
          <li parentName="ol">{`Governing Law and Dispute Resolution`}
            <p>These Terms of Use shall be governed by, and construed in accordance with, the laws of England and Wales. Each party hereby submits to the exclusive jurisdiction of the courts of England and Wales.</p>
          </li>
        </ol>
      </li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      